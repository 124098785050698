import React, { useState, useEffect, useRef, useContext } from "react";

// import './title_bar.css';
import "./title.css";
import { ITitleBar } from "../../../@type/titleBar";

import { SHARE_SVG, CLOSE_SVG, LOGO_SVG, ARROW_DOWN } from "./icons";
import { ACTION_HOVER_SVG } from "../SlidesNav/icons";
import languageContext from "../../../context/languageContext";

const TitleBar = ({
  display,
  setDisplayOverview,
  title,
  setTitle,
  onEmailShareflag,
}: ITitleBar) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [locTitle, setLocTitle] = useState(title);
  const [showShareDrp, setShowShareDrp] = useState(false);
  const drpContainerRef = useRef<HTMLDivElement>(null);
  const drpTimeoutRef = useRef<any>(null);
  const {
    flipsnack_desc,
    share_flipbook,
    share_pdf,
    share,
  }: {
    flipsnack_desc?: string;
    share_flipbook?: string;
    share_pdf?: string;
    share?: string;
  } = useContext(languageContext);

  useEffect(() => setLocTitle(title), [title]);

  useEffect(() => {
    if (drpTimeoutRef.current) {
      clearTimeout(drpTimeoutRef.current);
    }
    const ref = drpContainerRef.current;
    if (!ref) return;
    if (showShareDrp) {
      if (drpContainerRef.current.classList.contains("hide")) {
        drpContainerRef.current.classList.remove("hide");
      }
      drpTimeoutRef.current = setTimeout(() => {
        ref.classList.add("show");
      }, 50);
    } else {
      if (drpContainerRef.current.classList.contains("show")) {
        drpContainerRef.current &&
          drpContainerRef.current.classList.remove("show");
        drpTimeoutRef.current = setTimeout(() => {
          if (drpContainerRef.current) {
            drpContainerRef.current.classList.add("hide");
          }
        }, 400);
      }
    }
  }, [showShareDrp]);

  const editActivate = () => {
    setEdit(true);
    setTimeout(function () {
      const ele = document.getElementById("titleText");
      ele?.focus();
    }, 200);
  };

  const onLeave = () => {
    if (locTitle !== title) {
      setTitle(locTitle);
    }
    setEdit(false);
  };

  const focushere = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.selectionStart = title?.length;
    event.target.selectionEnd = Number(title?.length);
  };

  const handleChange = ({ target }: any) => {
    if (target.value.length <= 50) setLocTitle(target.value);
  };

  return (
    <div className="flex flex-shink-0 justify-space-between px20 py15 cc-title">
      <div className="flex">
        <div className="flex mr30">
          <div className="mr10 cc-tlogo">
            <LOGO_SVG />
          </div>
          <div className="flex align-center h-f gotham-black pfc-white">
            {!edit ? (
              <span className="cc-ttitle" onClick={editActivate}>
                {locTitle}
              </span>
            ) : (
              <input
                type="text"
                id="titleText"
                name="title"
                className="gotham-black cc-tinput"
                onFocus={focushere}
                value={locTitle}
                onChange={handleChange}
                onBlur={onLeave}
              />
            )}
          </div>
        </div>
        <div className="flex align-center relative action-btn-container">
          <button
            className="action btn-icon pfc-bright-blue btn-drp"
            onClick={(e) => {
              e.stopPropagation();
              setShowShareDrp(!showShareDrp);
            }}
          >
            <div>
              <span className="mr10">
                <SHARE_SVG />
              </span>
              <span>{share}</span>
            </div>
            <div
              className={
                (showShareDrp ? "rotate-180deg " : "") + "arrow-container"
              }
            >
              <ARROW_DOWN />
            </div>
          </button>

          <div ref={drpContainerRef} className="absolute drp-container hide">
            <label
              onClick={() => {
                setShowShareDrp(false);
                onEmailShareflag(false);
              }}
            >
              {share_pdf}
            </label>
            <label
              onClick={() => {
                setShowShareDrp(false);
                onEmailShareflag(true);
              }}
              className="flipsnack-container"
            >
              <div className="flipsnack-title">
                {share_flipbook} <div className="new-indicator">New!</div>
              </div>
            </label>
            <div className="flipsnack-content">{flipsnack_desc}</div>
          </div>
        </div>
      </div>
      <div>
        <div className="flex align-center h-f">
          <button
            onClick={() => setDisplayOverview(!display)}
            className="flex align-center pfc-white btn-clean"
          >
            <span className="mr10 gotham-book fz16">Close</span>
            <CLOSE_SVG />
          </button>
        </div>
      </div>
    </div>
  );
};
export default TitleBar;
