import React, { useState } from "react";

import { action, action_hover, EYE_SVG } from "./icons";

const Item = ({
    _id,
    visible = true,
    activeClass,
    index,
    order_no,
    name,

    onActiveChange,

    handleDrag,
    handleDrop,

    onItemAction,
    menu,
}: any) => {
    const [hover, setHover] = useState(false);

    const onShowAction = (event: any) => {
        event.stopPropagation();
        onItemAction(event, index, _id, order_no, visible);
    };

    return (
        <div
            className={`flex draggble cur-pointer cc-fitem ${activeClass}`}
            draggable={true}
            data-order={index}
            id={`stripitem_${index + 1}`}
            onDragOver={ev => ev.preventDefault()}
            onDragStart={handleDrag}
            onDrop={handleDrop}
        >
            <div className='flex' role='button' onClick={() => onActiveChange(index)}>
                <div className='fz12 cc-fino'>{index + 1}</div>

                <div className='flex flex-column' style={{ height: "102px" }}>
                    <div
                        style={{
                            height: "82px",
                            width: "146px",
                        }}
                    >
                        <img className='absolute' alt='' id={`strip_${index + 1}`} />
                    </div>
                    <div className='fz12 textalign-center' style={{ height: "20px", lineHeight: "20px" }}>
                        {name}
                    </div>
                </div>
            </div>
            <div className={`cc-faction ${menu.menuShow === true && _id === menu._id ? "act" : ""}`} onClick={() => onActiveChange(index)}>
                {visible !== true && (
                    <div className='slide-hidden'>
                        <EYE_SVG />
                    </div>
                )}
                <button className='item-action' onMouseOver={() => setHover(true)} onMouseOut={() => setHover(false)} onClick={onShowAction}>
                    <img src={hover === true || menu?._id === _id ? `data:image/svg+xml;charset=utf-8,${action_hover}` : `data:image/svg+xml;charset=utf-8,${action}`} alt='' />
                </button>
            </div>
        </div>
    );
};

export default Item;
