import React, { useState, useContext, useEffect } from "react";

import lookandFeelContext from "../../../../../context/LookAndFeelContext";
import languageContext from "../../../../../context/languageContext";

import Icons from "../../../../PillowSlide/atom/PT_SVG";
import MessageContext from "../../../../../context/MessageContext";
import { SAVE_COLOR_LIMIT, SAVE_COLOR_LIMIT_MESSAGE, SAVE_SAME_COLOR_MESSAGE, COLOR_DELETE_CONFIRM_MESSAGE } from "../../../../../constant";

interface PredefinedColorType {
    _id: string;
    main: string;
    accent1: string;
    accent2: string;
    name: string;
    order: number;
    isActive: boolean;
    isCorporate: boolean;
    fontColor: string;
    key?: string;
}

const Color = ({ colorpick }: any) => {
    const { element, onElementChangge, activeColor, colorScheme, isPredefineColor, isCustomColor, customColor, onToggleColor, onSaveColor, onDeleteColor }: any =
        useContext(lookandFeelContext);

    const language: any = useContext(languageContext);
    const { setMessage } = useContext(MessageContext);
    const { style = { value: [] }, colors = { value: [] } } = element;

    const initialvalue: any = {
        applied: null,
        colors: [],
        status: true,
    };

    const [mai, setmai] = useState(initialvalue);
    const [accent1, setaccent1] = useState(initialvalue);
    const [accent2, setaccent2] = useState(initialvalue);
    const [colorGroup, setColorGroup] = useState<PredefinedColorType[]>([]);
    const [activeColorGroup, setActiveColorGroup] = useState<any>({});

    const saveColorLimit = SAVE_COLOR_LIMIT;
    const saveColorLimitMessage = SAVE_COLOR_LIMIT_MESSAGE;
    const saveSameColourMessage = SAVE_SAME_COLOR_MESSAGE;
    // const saveColourSuccessMessage = "Colour set is saved.";
    const colourDeleteConfirmMessage = COLOR_DELETE_CONFIRM_MESSAGE;

    useEffect(() => {
        setColorGroup(colorScheme);
    }, [colorScheme]);
    useEffect(() => {
        setActiveColorGroup(activeColor);
    }, [activeColor]);

    useEffect(() => {
        if (colors.value[style.applied] !== undefined) {
            setmai(colors.value[style.applied]?.main);

            if (colors.value[style.applied]?.accent_1.applied !== undefined) setaccent1(colors.value[style.applied]?.accent_1);
            else setaccent1(colors.value[style.applied]?.main);

            if (colors.value[style.applied]?.accent_2.applied !== undefined) setaccent2(colors.value[style.applied]?.accent_2);
            else setaccent2(colors.value[style.applied]?.main);
        }
    }, [colors, style.applied]);

    const localColoPicker = (color: any, name: any, list: any) => {
        // console.log('color', color)
        if (color === null) return;
        let selected = "";
        if (list.includes(color) !== false) selected = "colorpickerpillow";
        return (
            <div className={`flex mt10 setting__element__color ${selected} colorpickerpillow`} onClick={() => colorpick({ color, name, list })}>
                <span className='setting__element__colorpicker cursor-pointer' style={{ background: color }}></span>
            </div>
        );
    };

    const onChangeCheck = (e: React.ChangeEvent<HTMLInputElement>, obj: any, set: any, target: string) => {
        const colorobj = {
            ...element,
            colors: {
                ...element.colors,
                value: {
                    ...element.colors.value,
                    [style.applied]: {
                        ...element.colors.value[style.applied],
                        [target]: {
                            ...element.colors.value[style.applied][target],
                            status: e.target.checked,
                        },
                    },
                },
            },
        };
        onElementChangge(colorobj.colors);
        console.log(obj, set);
    };

    const colorSelectionFn = (col: PredefinedColorType, obj: any) => {
        onToggleColor({
            payload: {
                ...obj,
                activeColorId: col === null ? "" : col._id,
            },
            col,
        });
        if (col !== null) setActiveColorGroup(col);
    };

    const isPredefineColorFn = (flag: boolean) => {
        const colorobj = {
            ...element,
            colors: {
                ...element.colors,
                value: {
                    ...element.colors.value,
                    [style.applied]: {
                        ...element.colors.value[style.applied],
                        main: {
                            ...element.colors.value[style.applied].main,
                            applied: element.colors.value[style.applied].main.colors[0],
                        },
                        accent_1: {
                            ...element.colors.value[style.applied].main,
                            applied: element.colors.value[style.applied].main.colors[1],
                        },
                        accent_2: {
                            ...element.colors.value[style.applied].main,
                            applied: element.colors.value[style.applied].main.colors[2],
                        },
                    },
                },
            },
        };

        onElementChangge(colorobj.colors);
        // return null;

        onToggleColor({
            payload: {
                isPredefinedColor: flag,
                isCustomColor: flag,
                activeColorId: activeColorGroup?._id,
            },
            col: {
                main: mai.colors[0],
                accent1: mai.colors[1],
                accent2: mai.colors[2],
            },
        });
    };

    /* const predifinedColorSelection = (col: PredefinedColorType) => {
        onToggleColor({
            payload: {
                isPredefinedColor: true,
                activeColorId: col._id,
            },
            col,
        });
        setActiveColorGroup(col);
    }; */

    const saveUsercolor = () => {
        // CHECK SAVED COLOUR LIMIT
        // SHOW MESSAGE BASE ON CONDITION
        let notValidLength = false;
        if (customColor.length >= saveColorLimit) {
            notValidLength = true;
            // setMessage({
            //     display: true,
            //     message: saveColorLimitMessage,
            //     fn: () => {},
            // })
            // return null;
        }

        // CHECK COLOUR SET ALREADY EXIST OR NOT
        let match = false;
        customColor.map((s: any) => {
            const m = mai.applied,
                o = accent1.applied !== undefined ? accent1.applied : mai.applied,
                t = accent2.applied !== undefined ? accent2.applied : mai.applied;
            if (s.main === m && s.accent1 === o && s.accent2 === t) {
                match = true;
            }
            return null;
        });

        if (isPredefineColor === true || isCustomColor === true) {
            match = true;
        }
        let msg = "";
        if (notValidLength) {
            msg = saveColorLimitMessage;
        }
        if (match) {
            if (msg === "") msg = saveSameColourMessage;
            else msg += "<br />" + saveSameColourMessage;
        }

        // STOP ADDING COLOURS IF ALREADY EXIST
        // SHOW MESSAGE
        if (match) {
            setMessage((prev: any) => ({
                ...prev,
                display: true,
                message: msg,
                fn: () => {
                    console.log("");
                },
            }));
            return null;
        }

        onSaveColor({
            main: mai.applied,
            accent1: accent1.applied !== undefined ? accent1.applied : mai.applied,
            accent2: accent2.applied !== undefined ? accent2.applied : mai.applied,
        });
    };

    const removeUsercolor = (color: any) => {
        setMessage((prev: any) => ({
            ...prev,
            display: true,
            type: "confirm",
            message: colourDeleteConfirmMessage,
            fn: () => {
                onDeleteColor(color);
                // setSaveColor((prev: any) => (prev.filter((p: any) => p._id !== color._id)));
            },
        }));
        return null;
    };

    // const radioIcon = (flag: boolean) => (flag ? <Icons.RadioActive /> : <Icons.RadioNormal />);

    return (
      <>
        {/* SELECTED COLOR DISPLAY */}
        <div className="cc2-color-panel mb20">
          {isPredefineColor === false && isCustomColor === false ? (
            <div className="flex mb20">
              <div className="mainColor fz12 text-lowercase-first">
                <label htmlFor="main" className="">
                  {language["main"]}
                </label>
                {localColoPicker(mai?.applied, "main", mai?.colors)}
              </div>
              <div className="flex1 textalign-right fz12 text-lowercase-first">
                <label htmlFor="main">{language["pillow_accent_colour"]}</label>

                <div className="flex flex-row-reverse">
                  {localColoPicker(
                    accent2?.applied,
                    "accent_2",
                    accent2?.colors
                  )}
                  {localColoPicker(
                    accent1?.applied,
                    "accent_1",
                    accent1?.colors
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="flex mb20">
              <div className="mainColor fz12 text-lowercase-first">
                <label htmlFor="main">{language["main"]}</label>
                <div className={`flex mt10 setting__element__color`}>
                  <span
                    className="setting__element__colorpicker"
                    data-main={activeColorGroup?.main}
                    style={{ background: activeColorGroup?.main }}
                  ></span>
                </div>
              </div>
              <div className="flex1 textalign-right fz12 text-lowercase-first">
                <label htmlFor="main">{language["pillow_accent_colour"]}</label>

                <div className="flex flex-row-reverse mt10">
                  <div className={`flex colorpickerpillow`}>
                    <span
                      className="setting__element__colorpicker"
                      data-accent2={activeColorGroup?.accent2}
                      style={{ background: activeColorGroup?.accent2 }}
                    ></span>
                  </div>
                  <div className={`flex colorpickerpillow`}>
                    <span
                      className="setting__element__colorpicker"
                      data-accent1={activeColorGroup?.accent1}
                      style={{ background: activeColorGroup?.accent1 }}
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          )}

          <button
            className="action ghost w-full mb20 fz14 bgColorNone"
            onClick={saveUsercolor}
          >
            <i className=" icon pfc-arrow-right mr8"></i>
            <span className="fw-350">Save colour scheme</span>
          </button>

          <div className="pfc-toggle label-left toggle_small justify-space-between mb10">
            <label>
              <input
                type="checkbox"
                checked={accent1?.status}
                name="accent1"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeCheck(e, accent1, setaccent1, "accent_1")
                }
              />
              <span className="slider"></span>
            </label>
            <span className="label text-regular_default fz14">
              {language["accent_1"] !== undefined
                ? language["accent_1"]
                : "Accent 1"}
            </span>
          </div>

          <div className="pfc-toggle label-left toggle_small justify-space-between ">
            <label>
              <input
                type="checkbox"
                checked={accent2?.status}
                name="accent1"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeCheck(e, accent2, setaccent2, "accent_2")
                }
              />
              <span className="slider"></span>
            </label>
            <span className="label text-regular_default fz14">
              {language["accent_2"] !== undefined
                ? language["accent_2"]
                : "Accent 2"}
            </span>
          </div>

          <div className={"flex justify-space-between mb10 hide"}>
            <label className="fz12 text-regular_small text-black flex align-center">
              {language["accent_1"] !== undefined
                ? language["accent_1"]
                : "Accent 1"}
            </label>
            <label className="cc_switch">
              <input
                type="checkbox"
                checked={accent1?.status}
                name="accent1"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeCheck(e, accent1, setaccent1, "accent_1")
                }
              />
              <span className="slider_pillow cursor-pointer round"></span>
            </label>
          </div>
          <div className={"flex justify-space-between hide"}>
            <label className="fz12 text-black flex align-center">
              {language["accent_2"] !== undefined
                ? language["accent_2"]
                : "Accent 2"}
            </label>
            <label className="cc_switch">
              <input
                type="checkbox"
                checked={accent2?.status}
                name="accent2"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onChangeCheck(e, accent2, setaccent2, "accent_2")
                }
              />
              <span className="slider_pillow cursor-pointer round"></span>
            </label>
          </div>
          <span className="down-arrow"></span>
        </div>

        <div className="c-box-wrap mb10 pb20">
          <div className="font-gotham_medium fz16 mb15 line-height_16">
            Logo colours
          </div>
          <span
            className={`flex align-center mb1f2 cur-pointer mr12 colorScheme-item ${
              isCustomColor === false && isPredefineColor === false
                ? "active"
                : ""
            }`}
          >
            <div
              className={`flex justify-space-between colorScheme-item-color ${
                isCustomColor === true || isPredefineColor === true
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={() => {
                if (isCustomColor === true || isPredefineColor === true) {
                  isPredefineColorFn(false);
                }
                // isCustomColor(false);
                // colorSelectionFn(null, {
                //     isPredefineColor: false,
                //     isCustomColor: false,
                // })
              }}
            >
              <span style={{ background: `${mai.colors[0]}` }}></span>
              <span
                style={{
                  background: `${
                    mai.colors[1] !== undefined ? mai.colors[1] : mai.colors[0]
                  }`,
                }}
              ></span>
              <span
                style={{
                  background: `${
                    mai.colors[2] !== undefined ? mai.colors[2] : mai.colors[0]
                  }`,
                }}
              ></span>
            </div>
          </span>
        </div>

        <div className="c-box-wrap mb10">
          <div className="font-gotham_medium fz16 mb15 line-height_16">
            Saved colour schemes
          </div>
          {customColor.length > 0 ? (
            <div className="flex flex-wrap w244">
              {customColor
                .slice(0, saveColorLimit)
                .map((col: PredefinedColorType) => (
                  <span
                    key={col._id}
                    className={`flex align-center mb15 cur-pointer color-deletable colorScheme-item ${
                      activeColorGroup?._id === col._id &&
                      isCustomColor === true
                        ? "active"
                        : ""
                    }`}
                    role="button"
                    onClick={(event: any) => {
                      if (event.target.tagName !== "SPAN") return null;
                      if (
                        isCustomColor === false ||
                        activeColorGroup?._id !== col._id
                      ) {
                        colorSelectionFn(col, {
                          isPredefinedColor: false,
                          isCustomColor: true,
                        });
                      }
                    }}
                  >
                    <div
                      className={`flex justify-space-between ${
                        activeColorGroup?._id !== col._id ||
                        isCustomColor === false
                          ? "cursor-pointer"
                          : ""
                      } colorScheme-item-color`}
                    >
                      <span style={{ background: col.main }} />
                      <span style={{ background: col.accent1 }} />
                      <span style={{ background: col.accent2 }} />
                    </div>
                    {col.isCorporate !== true && (
                      <div
                        className="color-delete-btn cursor-pointer"
                        onClick={() => {
                          removeUsercolor(col);
                        }}
                      >
                        <Icons.DeleteColorScehemeIcon />
                      </div>
                    )}
                  </span>
                ))}
            </div>
          ) : (
            <p className="gotham-book fz12 mb20">
              You haven't saved any colour schemes yet. Click on "Save colour
              scheme" button to create your personal colour library.
            </p>
          )}
        </div>

        <div className="c-box-wrap">
          <div className="font-gotham_medium fz16 mb15 line-height_16">
            Predefined Colours
          </div>
          {/* COLORSCHEME LIST */}
          <div className="flex flex-wrap w244">
            {colorGroup
              .sort((a, b) => a.order - b.order)
              .map((col: PredefinedColorType) => {
                const titleTip =
                  col.key !== undefined && language[col.key] !== undefined
                    ? language[col.key]
                    : col.name;
                return (
                  <span
                    key={col._id}
                    className={`flex align-center mb12 cur-pointer colorScheme-item ${
                      activeColorGroup?._id === col._id &&
                      isPredefineColor === true
                        ? "active"
                        : ""
                    }`}
                    title={titleTip}
                    role="button"
                    onClick={() => {
                      if (
                        isPredefineColor === false ||
                        activeColorGroup?._id !== col._id
                      ) {
                        colorSelectionFn(col, {
                          isPredefinedColor: true,
                          isCustomColor: false,
                        });
                      }
                    }}
                  >
                    <div
                      className={`flex justify-space-between ${
                        activeColorGroup?._id !== col._id ||
                        isPredefineColor === false
                          ? "cursor-pointer"
                          : ""
                      } colorScheme-item-color`}
                    >
                      <span style={{ background: col.main }} />
                      <span style={{ background: col.accent1 }} />
                      <span style={{ background: col.accent2 }} />
                    </div>
                  </span>
                );
              })}
          </div>
        </div>
      </>
    );
};

export default Color;
