import React, { useEffect, useContext, useState } from "react";

import TipDisplay from "../../Tip";
import languageContext from "../../../context/languageContext";
import boardContext from "../../../context/BoardContext";

// import './action_bar.css';
import "./action.css";

import {
    BACKGROUND_IMAGE,
    PRESENTATION_LOGO,
    END_CUSTOMER_LOGO,
    // END_CUSTOMER_LOGO_BG,
    TITLE_TEXT,
    TITLE_TEXT_BACKGROUND,
} from "../../../constant";

// import RIGHT_ARROW_SVG from './svg_right_arrow.svg';

import { COLOR_SVG, IMAGE_SVG } from "./icons";
import { ECQ_DEFAULT_SVG, E_PROOF_SVG, E_PROOF_PRICE_SVG } from "../SideBar/icons";
import { CAT_IMAGE_LIST } from "../SideBar/Template/Item";

const ActionBar = ({
    onResetApi,
    onSlideUpdate,
    current,
    localEdit = {
        background_color: "#fffff",
        element_id: "",
    },
    colorpickview,
    onBackgroundChange,
    elements = [],
    layout,
    templateId,
    onTemplateChange,
    // setTemplateId,
    typeName,
}: any) => {
    const { _id = "", element_id, group = "" } = localEdit;
    const board: any = useContext(boardContext);

    const {
        // font_color,
        // background,
        reset_to_default,
        layout_for_this_slide,
        // pillow_local_setting_text,
        ...languages
    }: any = useContext(languageContext);

    const [color, setColor] = useState("");
    const [id, setId] = useState("");

    // let color = '';
    // let id = '';

    useEffect(() => {
        const element = document.getElementById("localani");
        if (element) element.classList.add("active");
    }, []);

    useEffect(() => {
        if (elements.lenght > 0) {
            elements.map((e: any) => {
                if (element_id === BACKGROUND_IMAGE) {
                    if (e.group === group && e._id !== _id) {
                        setId(e._id);
                        setColor(e.fill);
                        // id = e._id;
                        // color = e.fill;
                    }
                }
                if (e.group === PRESENTATION_LOGO) {
                    if (e.group === group && e._id !== _id) {
                        setId(e._id);
                        setColor(e.fill);
                        // id = e._id;
                        // color = e.fill;
                    }
                }
                if (e.element_id === END_CUSTOMER_LOGO) {
                    if (e.group === group) {
                        setId(e._id);
                        const fill = e.fill !== undefined ? e.fill : board.editorLogo.background_color;
                        setColor(fill);
                        // id = e._id;
                        // color = e.fill !== undefined ? e.fill : board.editorLogo.background_color;
                    }
                }

                if (e.element_id === TITLE_TEXT) {
                    if (e._id === _id) {
                        setId(e._id);
                        setColor(e.fill);
                        // id = e._id;
                        // color = e.color;
                    }
                }
                if (e.element_id === TITLE_TEXT_BACKGROUND) {
                    if (e._id === _id) {
                        setId(e._id);
                        setColor(e.fill);
                        // id = e._id;
                        // color = e.fill;
                    }
                }
                return null;
            });
        }
    }, [elements, _id, element_id, group, board.editorLogo.background_color]);

    const templateChange = (_id: any, name: any) => {
        /**
         * ISSUE
         * Reset to default button disappeared after clicking
         * --------------------------------------------
         * after blocking setTemplateId, mentioned problem was fixed
         * couldn't find the cause what code was there in first place
         */
        // setTemplateId(_id);
        const { _id: slideid } = current;
        onTemplateChange(name, slideid);
    };

    function getName(element_id: any) {
        let name = "";
        if (element_id === "background_image") {
            name = "background_color";
        }
        if (element_id === "title_text") {
            name = "title_text";
        }
        if (element_id === "presentation_logo") {
            name = "presentation_logo_background";
        }
        if (element_id === "end_customer_logo") {
            name = "end_customer_logo_background";
        }
        if (element_id === "end_customer_logo_background") {
            name = "end_customer_logo";
        }
        if (element_id === "title_text_background") {
            name = "title_text_background";
        }
        return name;
    }

    // const backgroundflag = element_id === BACKGROUND_IMAGE;
    // const logoflag = backgroundflag
    //     || element_id === PRESENTATION_LOGO
    //     || element_id === END_CUSTOMER_LOGO
    //     || element_id === END_CUSTOMER_LOGO_BG
    //     || element_id === TITLE_TEXT_BACKGROUND;
    const textFlag = element_id === "title_text";

    const whichValue = localEdit?.element_id === "end_customer_logo_background" ? board.editorLogo?.background_color : board.presentationLogo?.background_color;
    // const style = {
    //     background: color ? color : textFlag ? board?.look?.font_color : whichValue
    // };

    const colorpick = () => {
        const name = getName(element_id);
        let fill = "";
        elements.map((ele: any) => {
            if (ele.element_id === name) {
                fill = ele.fill;
            }
            return null;
        });
        colorpickview({
            color: fill !== "" ? fill : color !== "" ? color : textFlag ? board.look.font_color : whichValue,
            name,
            id: element_id === TITLE_TEXT ? _id : id,
        });
    };

    /* const localMenuReset = () => {
        const retain = false;
        if (element_id === 'presentation_logo') {
            const resetData: any = [];
            elements
                .filter((e: any) => e.group === localEdit.group)
                .map((e: any) => {
                    if (e.type === 'shape') {
                        resetData.push({ id: e._id, type: 'fill', group, value: '', retain });
                        resetData.push({ id: e._id, type: 'visible', group, value: null, retain });
                    } else {
                        resetData.push({ id: e._id, type: 'visible', group, value: null, retain });
                    }
                    return null;
                });
            onSlideUpdate(resetData);
        }
        if (element_id === 'title_text_background') {
            const elements =
                localEdit.slideType === 'title_slide'
                    ? [localEdit.element_id, 'end_customer_logo_background']
                    : [localEdit.element_id];
            onResetApi({ slideId: current._id, slide_type: localEdit.slideType, elements });
        }
        if (element_id === 'background_image') {
            if (current.type === 'product_slide') {
                const filtered = {
                    name: '',
                    presentation_type_id: '',
                };
                layout.map((ll: any) => {
                    if (ll._id === templateId) {
                        filtered.name = ll.name;
                        filtered.presentation_type_id = ll.presentation_type_id;
                    }
                    return null;
                });
                templateChange(filtered.presentation_type_id, filtered.name);
                return null;
            }
            onResetApi({
                slideId: current._id,
                slide_type: localEdit.slideType,
                elements: ['background_color', 'background_image'],
            });
        }

        if (element_id === 'end_customer_logo_background') {
            // TODO :: after reset localMenu color not changing
            const resetData: any = [];
            elements
                .filter((e: any) => e.group === localEdit.group)
                .map((e: any) => {
                    if (e.type === 'shape') {
                        resetData.push({ id: e._id, type: 'fill', group, value: '', retain });
                        resetData.push({ id: e._id, type: 'visible', group, value: null, retain });
                    } else {
                        resetData.push({ id: e._id, type: 'visible', group, value: null, retain });
                        resetData.push({ id: e._id, type: 'fill', group, value: '', retain });
                    }
                    return null;
                });
            onSlideUpdate(resetData);
        }
        if (element_id === 'title_text') {
            onResetApi({ slideId: current._id, slide_type: localEdit.slideType, elements: ['title_text'] });
        }
        return null;
    }; */

    // CONTACT SLIDE
    if (current?.type === "contact_slide") {
        return <section className=' flex justify-space-between align-center py20 px30 cc-action'></section>;
    }

    // PILLOW PRODUCT SLIDE
    if (typeName === "pillow" && current !== null && current?.product_template_type !== undefined && current?.type === "product_slide") {
        return (
            <section className='flex align-center py20 px30 fz14 cc-action font-gotham_book'>
                {/* {pillow_local_setting_text} */}
                <span className='font-gotham_bold'>Tip:</span> <TipDisplay tiplist={board.tips} language={languages} />
            </section>
        );
    }

    const getImageSvg = (match: string, flag = false) => {
        const color = flag === true ? "#3082F9" : "#c3c3c3";
        switch (match) {
            case "default":
                return <ECQ_DEFAULT_SVG color={color} />;
            case "eproof":
                return <E_PROOF_SVG color={color} />;
            case "eproof_with_price":
                return <E_PROOF_PRICE_SVG color={color} />;
        }
    };

    // OLD CATALOGUE PRODUCT SLIDE
    if (current?.type === "product_slide") {
        return (
            <section className='flex justify-space-between align-center py20 px30 cc-action'>
                <div className='flex'>
                    <span className='mr10 fz14' style={{ width: "90px" }}>
                        {layout_for_this_slide}
                    </span>
                    <div className='flex'>
                        {layout.length > 0 &&
                            layout.map(({ _id, template_type, name, local_thumb }: any) => {
                                let flag = false;
                                if (current?.product_template_type !== null) {
                                    flag = template_type === current?.product_template_type;
                                }

                                if (CAT_IMAGE_LIST.includes(template_type) === true) {
                                    let flag = false;
                                    if (current?.product_template_type !== null) {
                                        flag = template_type === current?.product_template_type;
                                    }
                                    return (
                                        <span
                                            key={_id}
                                            className={flag ? "localmen-template-list mr10 active" : "localmen-template-list mr10"}
                                            onClick={flag ? () => null : () => templateChange(_id, name)}
                                        >
                                            <img src={flag ? local_thumb.active : local_thumb.inactive} alt={name} />
                                        </span>
                                    );
                                }

                                return (
                                    <div
                                        key={_id}
                                        className='cur-pointer action-template-image mr10'
                                        onClick={() => {
                                            if (flag !== true) {
                                                templateChange(_id, name);
                                            }
                                        }}
                                    >
                                        {getImageSvg(template_type, flag)}
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className='cc2_action_bar_panel_right flex-0'>
                    {/* <button className="action tertiary btn-medium btn-icon" onClick={localMenuReset}>
                        <i className=" icon pfc-arrow-right"></i>
                        <span>{reset_to_default} </span>
                    </button> */}
                </div>
            </section>
        );
    }

    if (current?.type === undefined) return null;

    // TITLE AND IMAGE SLIDE
    return (
        <section className='flex flex-shink-0 justify-space-between align-center py20 px30 cc-action'>
            <div className='cc2_action_bar_panel'>
                <ul className='flex cc-alist'>
                    <li>
                        <div role='button' className='flex align-center cur-pointer fz16' onClick={colorpick}>
                            <span>
                                <COLOR_SVG />
                            </span>
                            Color
                        </div>
                    </li>
                    <li>
                        <div
                            role='button'
                            className='flex align-center cur-pointer fz16'
                            onClick={() => {
                                onBackgroundChange(true);
                            }}
                        >
                            <span>
                                <IMAGE_SVG />
                            </span>
                            Image
                        </div>
                    </li>
                </ul>
            </div>

            <div className='flex-0 cc-aright'>
                {/* <button className="action tertiary btn-medium btn-icon" onClick={localMenuReset}>
                    <i className=" icon pfc-arrow-right"></i>
                    <span>{reset_to_default} </span>
                </button> */}
            </div>
        </section>
    );
};
export default ActionBar;
