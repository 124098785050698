import React, { useState, useContext } from "react";

import Themes from "./Themes/Themes";
import Design from "./Design/Design";
import Element from "./Element/Element";
import Margin from "./Margin/Margin";

import Look from "./Look/Look";
import Template from "./Template/Template";
import PElement from "./PElement/PElement";
import Logo from "./Logo/Logo";

import "./side.css";
import "./tabs.css";
import "./radio.css";

import { THEME_SVG, ELEMENT_SVG, DESIGN_SVG, MARGIN_SVG, BG_SVG, ARROW_SVG } from "./icons";

import { MenuContextType, MenuContext } from "../../../context/MenuContext";
import settingContext from "../../../context/SettingContext";
import themeContext from "../../../context/ThemeContext";

// settingViewUI
const SideBar = ({ colorpickview, setDim, onLookAndFeelChange, onPresentationLogoChange, onCustomerLogoChange }: any) => {
    const menuS = useContext<MenuContextType | null>(MenuContext);
    const { setting } = useContext<any>(settingContext);
    const { margin } = useContext<any>(themeContext);

    const keys = Object.keys(setting);
    const match: any = {
        themes: {
            icon: <THEME_SVG />,
        },
        content: {
            icon: <ELEMENT_SVG />,
        },
        shapes_and_colours: {
            icon: <DESIGN_SVG />,
        },
        product_template: {
            icon: <DESIGN_SVG />,
        },
        price_margin: {
            icon: <MARGIN_SVG />,
        },
        product_element: {
            icon: <ELEMENT_SVG />,
        },
    };
    const [panel, setPanel] = useState<string | null>(null);
    const [top, setTop] = useState(0);

    const activePane = (event: any, panelName: string) => {
        const target = event.target;

        // Get the position relative to the viewport
        const rect = target.getBoundingClientRect();
        const top = rect.top - rect.height + 6;
        setTop(top);

        if (panel === panelName) {
            setPanel(null);
            menuS?.updateSideMenu(false);
            setDim((old: any) => ({ ...old, sidemenu: false }));
            return;
        } else {
            setDim((old: any) => ({ ...old, sidemenu: true }));
            menuS?.updateSideMenu(true);
            setPanel(panelName);
        }
    };

    const panelDisplay = (panelName: string | null) => {
        switch (panelName) {
            case "themes":
                return <Themes />;
            case "content":
                return <Element colorpick={colorpickview} />;
            case "shapes_and_colours":
                return <Design colorpick={colorpickview} />;
            case "price_margin":
                return <Margin />;

            case "product_element":
                return (
                    <PElement
                        colorpick={colorpickview}
                        onPresentationLogoChange1={(obj: any) => onPresentationLogoChange(obj)}
                        onCustomerLogoChange1={(obj: any) => onCustomerLogoChange(obj)}
                    />
                );
            case "product_template":
                return <Template colorpick={colorpickview} onLookAndFeelChange={(obj: any) => onLookAndFeelChange(obj)} />;
            case "look_and_feel":
                return <Look colorpick={colorpickview} onLookAndFeelChange={(obj: any) => onLookAndFeelChange(obj)} />;
            case "logo":
                return (
                    <Logo
                        colorpick={colorpickview}
                        onPresentationLogoChange1={(obj: any) => onPresentationLogoChange(obj)}
                        onCustomerLogoChange1={(obj: any) => onCustomerLogoChange(obj)}
                    />
                );
            default:
                return null;
        }
    };

    const isActive = menuS && menuS?.sideMenu ? "active" : "";

    return (
        <aside className={`dyson flex relative cc-side ${isActive}`}>
            <div className='absolute cc-spanel'>
                <div className='cc-spwrap'>{panelDisplay(panel)}</div>
            </div>
            {/* {panel !== null && */}
            {menuS?.sideMenu === true && (
                <div
                    className='cc-spbtn hide'
                    onClick={() => {
                        menuS?.updateSideMenu(false);
                        setDim((old: any) => ({ ...old, sidemenu: false }));
                    }}
                >
                    <div className='cc-spbb'>
                        <BG_SVG />
                    </div>
                    <div className='cc-spba'>
                        <ARROW_SVG />
                    </div>
                </div>
            )}

            <div className='absolute cc-smenu'>
                <div className={`cc-smbox ${menuS?.sideMenu === true ? "on" : "off"}`} style={{ top }} />
                {keys.map((menu: any) => {
                    const { name, _id, root, label } = setting[menu];
                    if (root === false) return null;
                    const icon = match[name] !== undefined ? match[name].icon : null;
                    if (name === "price_margin" && margin === "") {
                        return null;
                    }
                    return (
                        <div
                            key={_id}
                            role='button'
                            className={`flex flex-column justify-center align-center p5 relative ${menuS && menuS?.sideMenu && panel === name ? "active" : ""}`}
                            onClick={(event: any) => activePane(event, name)}
                        >
                            <span className='flex justify-center align-center mb10'>{icon}</span>
                            <div className='small' dangerouslySetInnerHTML={{ __html: label }} />
                        </div>
                    );
                })}
            </div>
        </aside>
    );
};

export default SideBar;
